import { MotiView } from "moti";
import React, { useEffect, useState } from "react";

import {
  AnimationDurations,
  AnimationEasings,
} from "../../../theme/animations";
import { Pressable } from "../../primitive";

export type OverlayProps = {
  transparentBackground?: boolean;
  isClosing: boolean;
  onPress?: () => void;
};

export const Overlay = ({
  onPress,
  isClosing,
  transparentBackground,
}: OverlayProps) => {
  /**
   * This allows the pattern of rendering the overlay already open -- and still getting
   * the open animation. ie: {!isClosing ? <Overlay isClosing={isClosing} /> : null}
   */
  const [internalIsClosing, setInternalIsClosing] = useState(true);
  useEffect(() => {
    setInternalIsClosing(isClosing);
  }, [isClosing]);
  return (
    <MotiView
      animate={{ opacity: internalIsClosing ? 0 : 1 }}
      transition={{
        opacity: {
          type: "timing",
          duration: AnimationDurations.moderate02,
          easing: internalIsClosing
            ? AnimationEasings.exit
            : AnimationEasings.entrance,
        },
      }}
      style={{ position: "absolute", left: 0, right: 0, top: 0, bottom: 0 }}
    >
      <Pressable
        testID="modal-overlay"
        position={"absolute"}
        left={0}
        right={0}
        top={0}
        bottom={0}
        onPress={onPress}
        backgroundColor={
          transparentBackground ? "transparent" : "surface-overlay"
        }
        opacity={transparentBackground ? 1 : 0.6}
      />
    </MotiView>
  );
};
