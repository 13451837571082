import React, { ReactNode, useContext, useReducer } from "react";

import {
  iStaffAppState,
  StaffAppStateReducer,
  StaffAppStateReducerDispatch,
} from "./reducer";

export const WhooshSessionKey = "WhooshSession";

export interface iStaffAppContext extends iStaffAppState {
  dispatch: StaffAppStateReducerDispatch;
}

export const StaffAppContext = React.createContext<
  iStaffAppContext | undefined
>(undefined);

export const StaffAppContextProvider: React.FunctionComponent<{
  children?: ReactNode | ReactNode[];
}> = (props) => {
  const isLoggedIn = localStorage.getItem(WhooshSessionKey);

  const [state, dispatch] = useReducer(StaffAppStateReducer, {
    currentSession: null,
    isLoggedIn: !!isLoggedIn,
  });

  const contextValues = { ...state, dispatch };

  return (
    <StaffAppContext.Provider value={contextValues}>
      {props.children}
    </StaffAppContext.Provider>
  );
};

export const useStaffAppContext = () => {
  const context = useContext(StaffAppContext);
  if (!context) {
    throw new Error(
      "useStaffAppContext must be called within staff app context"
    );
  }
  return context;
};
