import { Icon, IIconProps } from "native-base";
import * as React from "react";
import { G, Path, Defs, ClipPath, Rect } from "react-native-svg";

export const StatusInformationFilledIcon = (props: IIconProps) => (
  <Icon viewBox="0 0 16 16" fill="none" {...props}>
    <G clipPath="url(#clip0_10199_1346)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM9 4C9 4.55228 8.55229 5 8 5C7.44772 5 7 4.55228 7 4C7 3.44772 7.44772 3 8 3C8.55229 3 9 3.44772 9 4ZM6.25 6.5C6.25 6.08579 6.58579 5.75 7 5.75H8C8.41421 5.75 8.75 6.08579 8.75 6.5V10.75H10C10.4142 10.75 10.75 11.0858 10.75 11.5C10.75 11.9142 10.4142 12.25 10 12.25H6C5.58579 12.25 5.25 11.9142 5.25 11.5C5.25 11.0858 5.58579 10.75 6 10.75H7.25V7.25H7C6.58579 7.25 6.25 6.91421 6.25 6.5Z"
        fill="currentColor"
      />
    </G>
    <Defs>
      <ClipPath id="clip0_10199_1346">
        <Rect width="16" height="16" fill="white" />
      </ClipPath>
    </Defs>
  </Icon>
);

export default StatusInformationFilledIcon;
