import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path, Rect } from "react-native-svg";

export const BlockSquareIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 12 12" color="currentColor" {...props}>
    <Rect width={12} height={12} fill="currentColor" rx={3} />
    <Path stroke="#fff" strokeLinecap="round" strokeWidth={1.5} d="m3 9 6-6" />
  </Icon>
);
