import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import {
  Environment,
  Network,
  Observable,
  QueryResponseCache,
  ROOT_TYPE,
  RecordSource,
  Store,
} from "relay-runtime";
import { SubscriptionClient } from "subscriptions-transport-ws";

import { ErrorCodes } from "@/design-system/errors/error_codes";
import history from "@/routes/history";
import { getBESocketUrl, getBEUrl } from "@/utilities/Environment";

const offlineResponseCache = new QueryResponseCache({
  size: 250,
  ttl: 60 * 60 * 24 * 1000,
});
/**
 * This function lets relay know that it can safely fulfil a 'node' query from
 * any object with the same ID. IT seems like relay would always do this by default - but this
 * function is what allows it to work cross query.
 *
 * It essentially says "if we query the root for 'node', then first check the cache for that ID."
 *
 * This makes it relatively safe to just query node(id) for data you need, and relay will
 * be smart enough to only call the backend if we need to
 *
 * Eventually this list of handlers might grow.
 *
 * NOTE: This file is JS rather than TS purely because Relay hasn't typed these missing field handlers
 * well and I was having to use : any all the time anyhow.
 */
const missingFieldHandlers = [
  {
    handle(field, record, argValues) {
      if (
        record != null &&
        record.__typename === ROOT_TYPE &&
        field.name === "node" &&
        argValues.hasOwnProperty("id")
      ) {
        return argValues.id;
      }
      return undefined;
    },
    kind: "linked",
  },
];

/**
 *
 * This handles shipping up files when uploading. NOTE: Absinthe on the backend
 * wants you to set the variable to the key of the file. ie: if you are uploading something
 * the variables would be member: 'myfilekey', then the uploadables would be {'myfilekey': FILE}.
 */
function getRequestBodyWithUploadables(text, variables, uploadables) {
  let formData = new FormData();
  formData.append("query", text);
  formData.append("variables", JSON.stringify(variables));

  Object.keys(uploadables).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
      formData.append(key, uploadables[key]);
    }
  });
  return formData;
}
async function fetchGraphQL(text, variables, uploadables) {
  const beUrl = await getBEUrl();
  const apiUrl = `${beUrl}/private/api`;
  const request = {
    method: "POST",
    headers: {},
    credentials: "include",
  };
  if (!!uploadables) {
    const body = getRequestBodyWithUploadables(text, variables, uploadables);
    request.body = body;
  } else {
    request.body = JSON.stringify({
      query: text,
      variables,
    });
    request.headers["Content-Type"] = "application/json";
  }

  const response = await fetch(apiUrl, request);
  const data = await response.json();
  const authStatus = response.headers.get("x-whoosh-authentication-status");
  const newSessionKey = response.headers.get("x-whoosh-session-key");
  if (!!newSessionKey) {
    localStorage.setItem("x-whoosh-session-key", newSessionKey);
  }
  if (["UNAUTHENTICATED", "INVALID"].includes(authStatus)) {
    // Otherwise we must be in the staff app - so send us to the login page.
    if (
      history.location.pathname.indexOf("guest-info") === -1 &&
      history.location.pathname !== "/login"
    ) {
      history.push("/login?nosession=true");
    }
  } else if (data.errors) {
    data.errors.forEach((error) => {
      //TODO: This should be moved into an Error Boundary and outside of this environment.
      if (error.message === ErrorCodes.ADMIN_REQUEST) {
        window.location.href = `${beUrl}/admin`;
      } else {
        Sentry.withScope(function (scope) {
          scope.setExtra("query", text);
          scope.setExtra("error", error);
          scope.setExtra("variables", variables);
          Sentry.captureException(new Error(error.extensions?.code));
        });
      }
    });
  }
  return data;
}

const isMutation = (text) => {
  return text.indexOf("mutation") === 0;
};

const getMutationKey = (text) => {
  const endIndex = text.indexOf("(input: $input)");
  const startIndex = text.lastIndexOf(" ", endIndex);
  const mutationName = text.substring(startIndex + 1, endIndex);
  return mutationName;
};

async function fetchRelay(params, variables, _cacheConfig, uploadables) {
  const isOnline = navigator ? navigator.onLine : false;

  /**
   * If we are not online, check our cache. If we have the thing, give the thing,
   * if not, give a notice of the error.
   */
  if (!isOnline) {
    if (isMutation(params.text)) {
      const mutationName = getMutationKey(params.text);
      const response = { data: {} };
      response.data[mutationName] = {
        errors: [
          {
            __typename: "BaseValidationError",
            code: "network_offline",
            field: null,
          },
        ],
      };
      return response;
    }
    const fromCache = offlineResponseCache.get(params.text, variables);
    const finalResponse = fromCache ? fromCache : { data: {} };
    return finalResponse;
  } else {
    const response = await fetchGraphQL(params.text, variables, uploadables);
    offlineResponseCache.set(params.text, variables, response);
    return response;
  }
}

const subscribe = (request, variables, subscriptionClient) => {
  const subscribeObservable = subscriptionClient.request({
    query: request.text,
    operationName: request.name,
    variables,
  });
  // Important: Convert subscriptions-transport-ws observable type to Relay's
  return Observable.from(subscribeObservable);
};

const useSubscriptionClient = () => {
  const [subscriptionClient, setSubscriptionClient] = useState(null);

  useEffect(() => {
    if (!subscriptionClient) {
      const getSubClient = async () => {
        const socketUrl = await getBESocketUrl();
        const wsUrl = `${socketUrl}/private/socket/websocket`;

        const newClient = new SubscriptionClient(wsUrl, {
          lazy: true,
          reconnect: true,
          connectionParams: () => {
            return {
              session_key: localStorage.getItem("x-whoosh-session-key"),
            };
          },
          connectionCallback: (error) => {
            if (error) {
              /**
               * When we have an invalid session key - we nuke our subscription client.
               * This will cause it to be recreated with whatever the current session key is
               * as well as refresh the app. Then if the main app query realizes we're logged out,
               * we will redirect to login OR if we are still logged in, our subscription client
               * will have been refreshed.
               *
               * This avoids spamming sentry with the "Invalid session key" error.
               */
              if (error === "Invalid session key") {
                setSubscriptionClient(null);
              } else {
                Sentry.captureException(error);
              }
            }
          },
        });

        setSubscriptionClient(newClient);
      };
      getSubClient();
    }
  }, [subscriptionClient]);

  return subscriptionClient;
};

/**
 *
 * We now need to await an api call to get the right subscription env.
 * We use this hook to allow components to abstract away that async.
 */
export const useRelayPrivateEnvironment = () => {
  const [privateEnvironment, setPrivateEnvironment] = useState(null);

  const subscriptionClient = useSubscriptionClient();

  useEffect(() => {
    if (!privateEnvironment && subscriptionClient) {
      const getEnv = async () => {
        const subscribeFunction = (request, variables) => {
          return subscribe(request, variables, subscriptionClient);
        };
        const env = new Environment({
          network: Network.create(fetchRelay, subscribeFunction),
          store: new Store(new RecordSource()),
          missingFieldHandlers: missingFieldHandlers,
        });
        setPrivateEnvironment(env);
      };
      getEnv();
    }
  }, [privateEnvironment, subscriptionClient]);
  return privateEnvironment;
};
