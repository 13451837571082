import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const CloseOutIcon = (props: IIconProps) => {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <Path
        d="M7.5 4H5C4.44772 4 4 4.44772 4 5V11C4 11.5523 4.44772 12 5 12H7.5"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Path
        d="M11 6L13 8L11 10"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <Path
        d="M13 8L8 8"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </Icon>
  );
};
