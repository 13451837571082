import { iCurrentSession } from "./types";

export interface iStaffAppState {
  currentSession: iCurrentSession | null;
  isLoggedIn: boolean;
}

export type StaffAppStateReducerDispatch = (action: StaffAppAction) => void;

type StaffAppAction =
  | { type: "setSession"; payload: iCurrentSession }
  | { type: "clearSession" };

export const StaffAppStateReducer = (
  state: iStaffAppState,
  action: StaffAppAction
) => {
  switch (action.type) {
    case "setSession":
      return { ...state, isLoggedIn: true, currentSession: action.payload };
    case "clearSession":
      return { ...state, isLoggedIn: false, currentSession: null };
    default:
      return state;
  }
};
