import React from "react";

import { OfflineToast } from "@/staffApp/features/OfflineToast";
import { ServiceWorkerToast } from "@/staffApp/features/ServiceWorkerToast";

import { StaffAppContextProvider } from "./appState";
import { StaffAppRoutes } from "./routes";

const StaffApp = () => {
  return (
    <>
      <OfflineToast />
      <ServiceWorkerToast />
      <StaffAppContextProvider>
        <StaffAppRoutes />
      </StaffAppContextProvider>
    </>
  );
};

export default StaffApp;
