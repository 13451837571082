import { t } from "@lingui/macro";
import * as Sentry from "@sentry/react";
import React, { lazy, Suspense, useEffect } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import { Switch, Route, useHistory } from "react-router-dom";

import { Button } from "@/design-system/controls/Button";
import { BasicErrorScreen } from "@/design-system/errors/BasicErrorScreen";
import Loading from "@/design-system/overlay/Loading";
import { useRelayPrivateEnvironment } from "@/relay/environment";

import { useStaffAppContext, WhooshSessionKey } from "../appState";

const AppShell = lazy(() => import("./AppShell"));
const LoginView = lazy(() => import("@/staffApp/views/LoginView"));

export const StaffAppRoutes = () => {
  const { isLoggedIn, dispatch } = useStaffAppContext();
  const history = useHistory();
  useEffect(() => {
    const noSession = history.location.search.indexOf("nosession") !== -1;

    if (
      history.location.pathname === "/login" ||
      history.location.pathname === "/"
    ) {
      if (isLoggedIn) {
        if (noSession) {
          localStorage.removeItem(WhooshSessionKey);
          dispatch({ type: "clearSession" });
        } else {
          history.push("/app");
        }
      }
    }
  }, [isLoggedIn, history, dispatch]);

  const privateEnv = useRelayPrivateEnvironment();

  const logoutButton = (
    <Button onClick={() => history.push("/app/logout")}>
      {t`Click here to logout`}
    </Button>
  );

  return privateEnv ? (
    <RelayEnvironmentProvider environment={privateEnv}>
      <Switch>
        <Route path="/app">
          <Sentry.ErrorBoundary
            fallback={({ resetError }) => (
              <BasicErrorScreen
                logoutButton={logoutButton}
                resetError={resetError}
              />
            )}
          >
            <AppShell />
          </Sentry.ErrorBoundary>
        </Route>
        <Route path="/">
          <Suspense fallback={<Loading />}>
            <LoginView />
          </Suspense>
        </Route>
      </Switch>
    </RelayEnvironmentProvider>
  ) : null;
};
