import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const WarningOutlineIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" color="currentColor" {...props}>
    <Path
      fill="currentColor"
      d="M8 3.75a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-1.5 0v-4A.75.75 0 0 1 8 3.75ZM8 12a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
    />
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="m.522 6.63 2.65-4.416A2.5 2.5 0 0 1 5.316 1h5.368a2.5 2.5 0 0 1 2.144 1.214l2.65 4.416a2.5 2.5 0 0 1 0 2.573l-2.65 4.417a2.5 2.5 0 0 1-2.144 1.213H5.316a2.5 2.5 0 0 1-2.144-1.213L.522 9.203a2.5 2.5 0 0 1 0-2.573Zm1.287.772a1 1 0 0 0 0 1.03l2.65 4.416a1 1 0 0 0 .857.485h5.368a1 1 0 0 0 .857-.485l2.65-4.417a1 1 0 0 0 0-1.029l-2.65-4.417a1 1 0 0 0-.857-.485H5.316a1 1 0 0 0-.857.486l-2.65 4.416Z"
      clipRule="evenodd"
    />
  </Icon>
);
