import { MotiView } from "moti";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Platform, Modal as RNModal } from "react-native";

import { useKeyboardBottomInset } from "../../../hooks/useKeyboardBottomInset";
import { usePreventScroll } from "../../../hooks/usePreventScroll";
import {
  AnimationDurations,
  AnimationEasings,
} from "../../../theme/animations";
import { Overlay } from "../Overlay/Overlay";
import { WhooshToastProvider } from "../Toast/WhooshToastProvider";
import { ModalProps } from "./types";
import { useIsModalFocused } from "./useIsModalFocused";

export const Modal = ({ isOpen, onClose, children }: ModalProps) => {
  const [isClosing, setIsClosing] = useState(false);
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const isFocused = useIsModalFocused();

  const handleInternalClose = useCallback(() => {
    setInternalIsOpen(false);
    setIsClosing(false);
    onClose?.();
  }, [onClose]);

  const handleClose = useCallback(() => {
    if (onClose) {
      setIsClosing(true);
      handleInternalClose();
    }
  }, [handleInternalClose, onClose]);

  useEffect(() => {
    if (isOpen) {
      /**
       * When we open -- we need to slightly delay the open
       * by the same duration as our debounced close + a small buffer.
       *
       * This is because otherwise opening one modal while closing another can cause a clash
       * resulting in the 2nd modal getting stuck. This is related to using 'transparent=true'
       * I believe.
       *
       * GH: https://github.com/facebook/react-native/issues/29929
       */

      setIsClosing(false);
      setInternalIsOpen(true);
    } else if (!isOpen && internalIsOpen) {
      setIsClosing(true);
      handleInternalClose?.();
    }
  }, [handleInternalClose, internalIsOpen, isOpen]);

  usePreventScroll({ isOpen });

  const isModalOpen = isOpen || internalIsOpen;
  return isFocused ? (
    <RNModal
      visible={isModalOpen}
      transparent={true}
      onRequestClose={handleClose}
    >
      <WhooshToastProvider>
        <Content isClosing={isClosing}>
          <Overlay onPress={handleClose} isClosing={isClosing} />
          {children}
        </Content>
      </WhooshToastProvider>
    </RNModal>
  ) : null;
};

const Content = ({
  isClosing,
  children,
}: {
  isClosing: boolean;
  children: ReactNode;
}) => {
  const bottomInset = useKeyboardBottomInset();
  /**
   * This allows the pattern of rendering the modal already open -- and still getting
   * the open animation. ie: {isOpen ? <Modal isOpen={isOpen} /> : null}
   */
  const [internalIsClosing, setInternalIsClosing] = useState(true);
  useEffect(() => {
    setInternalIsClosing(isClosing);
  }, [isClosing]);
  return (
    <MotiView
      key="modalcontent"
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: -30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        paddingBottom: Platform.OS === "android" ? bottomInset : 0,
      }}
      animate={{
        opacity: internalIsClosing ? 0 : 1,
        top: internalIsClosing ? -30 : 0,
      }}
      transition={{
        opacity: {
          type: "timing",
          duration: AnimationDurations.moderate02,
          easing: internalIsClosing
            ? AnimationEasings.exit
            : AnimationEasings.entrance,
        },
        top: {
          type: "timing",
          duration: AnimationDurations.moderate02,
          easing: internalIsClosing
            ? AnimationEasings.exit
            : AnimationEasings.entrance,
        },
      }}
    >
      {children}
    </MotiView>
  );
};
