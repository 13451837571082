import { IIconProps, Icon } from "native-base";
import * as React from "react";
import { Path } from "react-native-svg";

export const CautionMinorOutlineIcon = (props: IIconProps) => (
  <Icon fill="none" viewBox="0 0 16 16" {...props}>
    <Path
      fill="currentColor"
      d="M8 4.75a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-1.5 0v-3A.75.75 0 0 1 8 4.75ZM9 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
    />
    <Path
      fill="currentColor"
      fillRule="evenodd"
      d="M14 14.5a1.5 1.5 0 0 0 1.317-2.218l-6-11a1.5 1.5 0 0 0-2.634 0l-6 11A1.5 1.5 0 0 0 2 14.5h12ZM8 2l6 11H2L8 2Z"
      clipRule="evenodd"
    />
  </Icon>
);
